import React from "react"
import i18next from '../i18n/config';
import {Link, Trans, useTranslation, useI18next} from 'gatsby-plugin-react-i18next';

// import ImgHeader from "../img/A_glowna_1/tlo_glowna.png";
import ImgFabricPL from "../img/P_nasze_zaklady_7/polska@0,75x.jpg";
import ImgFabricVN from "../img/P_nasze_zaklady_7/wietnam@0,75x.jpg";
import ImgFabricRU from "../img/P_nasze_zaklady_7/rosja@0,75x.jpg";
import ImgFabricCO from "../img/P_nasze_zaklady_7/kolumbia@0,75x.jpg";
// import ImgMap from "../img/P_nasze_zaklady_7/mapa_zklady@0,75x.jpg";
import IkoPassion from "../img/P_nasze_zaklady_7/iko_pasja.png";
import IkoFlex from "../img/P_nasze_zaklady_7/iko_elastycznosc.png";
import IkoGlob from "../img/P_nasze_zaklady_7/iko_globalizacja.png";
import IkoDevelop from "../img/P_nasze_zaklady_7/iko_rozwoj.png";
import IkoInv from "../img/P_nasze_zaklady_7/iko_innowacja.png";
import IkoQlt from "../img/P_nasze_zaklady_7/iko_jakosc.png";

import ImgDiagY from "../img/mobile/historia_en.jpg";
import ImgDiagXx from "../img/P_nasze_zaklady_7/historia_os.jpg";

import ImgDiagPLY from "../img/mobile/historia_y_pl.jpg";
import ImgDiagPLX from "../img/P_nasze_zaklady_7/historia_os_pl.jpg";

import ImgDiagES from "../img/mobile/historia_es.jpg";
import ImgDiagESX from "../img/P_nasze_zaklady_7/historia_os_es.jpg";




import ImgAbUs from "../img/P_nasze_zaklady_7/o_nas.png";
import ImgOurPl from "../img/P_nasze_zaklady_7/nasze_zaklady.png";


export default function Header(props) {



                return (
                        <React.Fragment>


    <section id="aboutus" class="aboutus firstSection">
        <h1>{i18next.t('AboutUsH2')}</h1>
        <p>{i18next.t('AboutUsDesc')}</p>
        <p>{i18next.t('AboutUsDesc2')}</p>
        <div class="icons">
            <div class="icon">
                <div> <img loading="lazy" loading="lazy" src={IkoPassion} alt="Instanta PassionF" />
                    <p>{i18next.t('IcoPassion')}</p>
                </div>
            </div>
            <div class="icon">
                <div> <img loading="lazy" loading="lazy" src={IkoFlex} alt="Instanta Flexibility" />
                    <p>{i18next.t('IcoFlex')}</p>
                </div>
            </div>
            <div class="icon">
                <div> <img loading="lazy" loading="lazy" src={IkoGlob} alt="Instanta Globalisation" />
                    <p>{i18next.t('IcoGlob')}</p>
                </div>
            </div>
            <div class="icon">
                <div> <img loading="lazy" loading="lazy" src={IkoDevelop} alt="Instanta Development" />
                    <p>{i18next.t('IcoDevelop')}</p>
                </div>
            </div>
            <div class="icon">
                <div> <img loading="lazy" loading="lazy" src={IkoInv} alt="Instanta Innovation" />
                    <p>{i18next.t('IcoInv')}</p>
                </div>
            </div>
            <div class="icon">
                <div> <img loading="lazy" loading="lazy" src={IkoQlt} alt="Instanta Quality" />
                    <p>{i18next.t('IcoQlt')}</p>
                </div>
            </div>
        </div>
        {
                        // <aside class="OurPlantsIcon">
                        //    <Link to={'/AboutUs/OurPlants/'}>
                        //    <img loading="lazy" loading="lazy" src={ImgOurPl} alt="Instanta OurPlants Icon" />
                        //    <p>{i18next.t('OurPlantsIcon')}</p>
                        //    </Link>
                        // </aside>
        }











    </section>

    <section id="history" class="history">
        <h3>










                {i18next.t('HistoryH3')
            }










        </h3>














                {i18next.t('lang') == "pl" && (
                                <div>
                                    <img loading="lazy" class="diagY" src={ImgDiagPLY}  alt="Instanta Road Map" />
                                    <img loading="lazy" class="diagX" src={ImgDiagPLX}  alt="Instanta Road Map" />
                                </div>
                        )
        }












        {i18next.t('lang') == "en" && (
                        <div>
                            <img loading="lazy" class="diagY" src={ImgDiagY}  alt="Instanta Road Map Y" />
                            <img loading="lazy" class="diagX" src={ImgDiagXx}  alt="Instanta Road Map X" />
                        </div>
                        )
        }












        {i18next.t('lang') == "es" && (
                        <div>
            <div>
                <img loading="lazy" class="diagY" src={ImgDiagES}  alt="Instanta Road Map" />
                <img loading="lazy" class="diagX" src={ImgDiagESX}  alt="Instanta Road Map" />
            </div>
        </div>
                        )}














    </section>

    <section id="banner" class="banner">

        <h4>











                {i18next.t('VisionH4')}</h4>
        <p>{i18next.t('VisionDesc')}</p>

    </section>

</React.Fragment>

                )


}
